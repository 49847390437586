<template>
  <div>
    <router-link :to="{ name: 'moderator.announcements.create' }" class="btn btn-primary btn-sm py-2 px-2 float-right">Make New</router-link>
    <h2 class="h3">Announcements</h2>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive-md table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(announcement, i) in announcements" :key="`announcement-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ announcement.title }}</td>
              <td>{{ announcement.date }}</td>
              <td>
                <router-link :to="{ name: 'moderator.announcements.show', params: { id: announcement.id }}">Preview</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      announcements: []
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get('/api/v1/moderator/announcements').then(response => {
        this.announcements = response.data.announcements
        this.$loader.stop()
      })
    }
  }
}
</script>